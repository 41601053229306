import react, {useEffect, useState} from 'react'
import Landing from '@/Layouts/Landing'
import { Box, HStack, VStack, Stack, Text, Button, ButtonGroup, useDisclosure, Avatar, AvatarBadge, AvatarGroup} from '@chakra-ui/react'
import BidPage from '../Bid/Bid'
import NFTItem from '../NFT/Item'
import CreatorItem from '../Creators/Item'

const Home = ({balance, creators, nfts, auth}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [bidNFT, setBidNFT] = useState(null)

    useEffect(() => {
        const buttonWrapper = document.querySelector('.button-wrapper');
        console.log(buttonWrapper)

        function scrollButtons(event) {
            if (event.deltaY < 0) {
                // scrolling up
                buttonWrapper.scrollLeft -= 100;
            } else {
                // scrolling down
                buttonWrapper.scrollLeft += 100;
            }
        }

        buttonWrapper.addEventListener('wheel', scrollButtons);
    })

    return (
        <Landing auth={auth}>


            <section>
                <Box className="section-padding-top" height={'auto'} backgroundImage={'url(images/hero/hero-bg.avif)'} backgroundSize={'cover'}>

                    <VStack style={{marginTop: 100}}>
                        <VStack zIndex={0} spacing={-200}>
                            <Box position={'absolute'} zIndex={-1} marginTop={30}>
                                <Text marginBottom={-10} className='text-white text-right hero-sup' >Collect & Sell NFTs with</Text>
                                <Text fontFamily={'Boucle2'} className='text-white hero-site-name'> <span className='brand-text'>Pixel</span>Vaults</Text>
                            </Box>
                            <Box>
                                <img position={'absolute'} zIndex={1} src="images/hero/hero-art-3.png" width={'auto'} alt="" />
                            </Box>
                        </VStack>
                    </VStack>

                        
                </Box>

            </section>
            
            <section>

                <Box className='stat-container' height={300} backgroundImage={'url(images/hero/stat-bg.avif)'} backgroundSize={'cover'} backgroundPosition={'center'} padding={10}>

                    <Box className="row" justifyContent={'space-between'} alignItems={'center'} height={'100%'}> 
                        <Box className="col-lg-4 col-md-4 col-sm-12 col-xl-4" display={'flex'} justifyContent={'center'}>
                            <Box textAlign={'center'}>
                                <Text as={'b'} className='brand-text stat-value' fontFamily={'Boucle2'}>365K<span style={{fontFamily: 'Boucle2'}}>+</span></Text>
                                <Text mt={-5} fontFamily={'Boucle2'} className='stat-title' color={'#adacb3'}>Artwork</Text>
                            </Box>
                        </Box>

                        <Box className="col-lg-4 col-md-4 col-sm-12 col-xl-4" display={'flex'} justifyContent={'center'}>
                            <Box textAlign={'center'}>
                                <Text as={'b'} className='brand-text stat-value' fontFamily={'Boucle2'}>78K<span style={{fontFamily: 'Boucle2'}}>+</span></Text>
                                <Text mt={-5} fontFamily={'Boucle2'} className='stat-title' color={'#adacb3'}>Auction</Text>
                            </Box>
                        </Box>

                        <Box className="col-lg-4 col-md-4 col-sm-12 col-xl-4" display={'flex'} justifyContent={'center'}>
                            <Box textAlign={'center'}>
                                <Text as={'b'} className='brand-text stat-value' fontFamily={'Boucle2'}>55K<span style={{fontFamily: 'Boucle2'}}>+</span></Text>
                                <Text mt={-5} fontFamily={'Boucle2'} className='stat-title' color={'#adacb3'}>Creators</Text>
                            </Box>
                        </Box>
                    </Box>

                </Box>

                
            </section>


            <section>

                <Box className='section-padding' background={'linear-gradient(155deg, rgba(16,32,73,1) 0%, rgba(15,33,70,0.1049194677871149) 40%)'}>

                    <div className="container">  
                        <Box className="row">
                            <div className="col-12">
                                <div className="section-heading text-center mb-5">
                                    <Text className='text-white section-title' fontFamily={'Cosmata Bold'} fontSize={100}>Top Creators</Text>
                                </div>
                            </div>
                        </Box>

                        <div className="row g-5">
                            {
                                creators.map(creator => {
                                    return (
                                        <CreatorItem from={'home'} auth={auth} key={creator.id} creator={creator} height={400} column={'col-lg-6 col-xl-4 col-md-6 col-sm-12'} />
                                    )
                                })  
                            }
                        </div>

                    </div>

                </Box>  

            </section>


            <section>

                <Box className='section-padding' background={'linear-gradient(-10deg, rgba(16,32,73,1) 0%, rgba(15,33,70,0.1049194677871149) 40%)'}>

                    <div className="container">  

                        <Box className="row">
                            <div className="col-12">
                                <div className="section-heading text-center mb-5">
                                    <Text className='text-white section-title' fontFamily={'Cosmata Bold'}>Live Bidding</Text>
                                </div>
                            </div>
                        </Box>

                        <div className="row g-5">
                            
                            <NFTItem from={'home'} auth={auth} balance={balance} nfts={nfts} height={400} column={'col-lg-4 col-xl-3 col-md-6 col-sm-12'} />

                        </div>

                    </div>

                </Box>  

            </section>


            <section>

                <Box className='section-padding' background={'linear-gradient(225deg, rgba(16,32,73,1) 0%, rgba(15,33,70,0.1049194677871149) 120%)'}>

                    <div className="container">  

                        <Box className="row">
                            <div className="col-12">
                                <div className="section-heading text-center mb-5">
                                    <Text className='text-white section-title' fontFamily={'Cosmata Bold'}>Get Started</Text>
                                </div>
                            </div>
                        </Box>

                        <div className="row g-5">
                            <div className="col-12 col-md-4">
                                <div className="text-center">
                                    <span className="brand-text fa-3x"><i className="ri-user-add-fill"></i></span>
                                    <Text className='text-white fw-bold' fontFamily={'Cosmata Bold'} fontSize={25}>Create An Account</Text>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="text-center">
                                    <span className="brand-text fa-3x"><i className="ri-wallet-3-fill"></i></span>
                                    <Text className='text-white fw-bold' fontFamily={'Cosmata Bold'} fontSize={25}>Fund Your Wallet</Text>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="text-center">
                                    <span className="brand-text fa-3x"><i className="ri-store-2-fill"></i></span>
                                    <Text className='text-white fw-bold' fontFamily={'Cosmata Bold'} fontSize={25}>Start Minting and Auctioning</Text>
                                </div>
                            </div>
                        </div>

                    </div>

                </Box>  

            </section>

            <section>

                <Box className='section-padding' background={'linear-gradient(194deg, rgba(16,32,73,0.9) 0%, rgba(15,33,70,0.1049194677871149) 40%)'}>

                    <div className="container">  

                        <Box className="row">
                            <div className="col-12">
                                <div className="section-heading text-center mb-10">
                                    <Text className='text-white section-title' fontFamily={'Cosmata Bold'}>Explore Marketplace</Text>
                                    <Text className="text-gray section-description" fontFamily={'Cosmata Bold'}> Browse, Buy, and Showcase Your Favorite NFT Masterpieces</Text>
                                </div>
                            </div>
                        </Box>

                        <HStack justifyContent={'center'} marginBottom={20} width={'100%'} overflowX={'auto'}>
                            <HStack spacing={10} className='button-wrapper'>
                                <Button size={'lg'} className='btn brand-bg' fontFamily={'Boucle2'} borderRadius={10} padding={7}>
                                    Latest NFT's
                                </Button>
                                <Button size={'lg'} className='btn dark-bg-2 text-white' fontFamily={'Boucle2'} borderRadius={10} padding={7}>
                                    Trending NFT's
                                </Button>
                                <Button size={'lg'} className='btn dark-bg-2 text-white' fontFamily={'Boucle2'} borderRadius={10} padding={7}>
                                    Featured NFT's
                                </Button>
                                <Button size={'lg'} className='btn dark-bg-2 text-white' fontFamily={'Boucle2'} borderRadius={10} padding={7}>
                                    Most Sold NFT's
                                </Button>
                                <Button onClick={() => { window.location.href = '/store' }} color={'#ccf300'} _hover={{backgroundColor: '#ccf300', color: '#000'}} variant={'ghost'} rightIcon={<i className="ri-external-link-fill"></i>} size={'lg'} className='brand-text' fontFamily={'Boucle2'} borderRadius={10} padding={7}>
                                   All
                                </Button>
                            </HStack>
                        </HStack>

                        <div className="row g-5">
                            
                            <NFTItem from={'home'} auth={auth} balance={balance} nfts={nfts} height={400} column={'col-lg-4 col-xl-3 col-md-6 col-sm-12'} />

                        </div>

                    </div>

                </Box>  

            </section>

            <section>

                <Box className='cta-container' height={700} backgroundImage={'url(images/hero/stat-bg.avif)'} backgroundSize={'cover'} backgroundPosition={'center'} padding={'0px 20px'}> 

                    <Box className="row cta-row" height={'100%'} overflow={'hidden'}>
                        <Box className="col-lg-6">
                            <VStack className='cta-img-container' height={'100%'} justifyContent={'flex-end'} alignItems={'flex-start'}>
                                <img width={750} src="images/hero/cta.png" alt="" />
                            </VStack>
                        </Box>

                        <Box className="col-lg-6" margin={'auto 0'}>
                            <Box className="section-heading mb-10 section-content" margin={'0 auto'}>
                                <Text className='text-white cta-title' fontFamily={'Cosmata Extra Bold'} lineHeight={1.35}>Unlock the World of Digital Artistry</Text>
                                <Text className="text-white cta-description" fontFamily={'Cosmata Regular'}> Dive into the Ultimate NFT Marketplace Today! Explore, Collect, 
                                and Trade Exceptional NFTs with Just a Few Clicks. Don't Miss Out - Join the Revolution Now!</Text>
                                <Button className='btn brand-bg text-center' onClick={() => { window.location.href = '/register' }} paddingTop={8} paddingBottom={8} paddingRight={10} paddingLeft={10} rightIcon={<i className="ri-arrow-right-line"></i>} marginTop={5} size={'lg'} fontSize={25} borderRadius={10}>
                                    Register
                                </Button>
                            </Box>
                        </Box>
            

                    </Box>

                </Box>


            </section>



            {
                bidNFT && balance !== -1 ? (
                    <Bid isOpen={isOpen} onOpen={onOpen} onClose={onClose} nft={bidNFT} balance={balance} listing={bidNFT.listing} />
                ) : (<></>)
            }

        </Landing>
    )
}

export default Home